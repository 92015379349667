import { Box, Typography, Button } from "@mui/material";
import { useContext, useState } from "react";
import { ConversationContext } from "../context/ConversationContext";
import { resetUserState } from "../service/resetUserState";
import { resetCursor } from "../service/resetCursor";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

export default function DeliveryManInfo({ ops, clientName }) {
	const { conversationData } = useContext(ConversationContext);
	const { nome_completo, phone, cursor, userState, chave_pix, address, numero_documento, status, dont_has_azumi } = conversationData;

  const accessToken = useSelector((state) => state.auth0.accessToken);
  const { logout } = useAuth0();

	const handleResetCursor = async () => {
		await resetCursor(phone, clientName, ops, accessToken, logout);
	};

	const handleResetUserState = async () => {
		await resetUserState(phone, clientName, ops, accessToken, logout);
	};

	return (
		<Box height="100%" bgcolor="#111b21" padding={3}>
			<Typography variant="h4" fontWeight="bold" mb={2}>
				Informações do Entregador
			</Typography>

			<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
				<Typography variant="h5">
					<strong>Nome:</strong> {nome_completo}
				</Typography>
				<Typography variant="h4">
					<strong>CPF/CNPJ:</strong> {numero_documento}
				</Typography>
				<Typography variant="h4">
					<strong>Chave Pix:</strong> {chave_pix}
				</Typography>
				<Typography variant="h4">
					<strong>Telefone:</strong> {phone}
				</Typography>
				<Typography variant="h4">
					<strong>Endereço:</strong> {address}
				</Typography>
				<Typography variant="h4">
					<strong>Status:</strong> {status}
				</Typography>
        <Typography variant="h4">
					<strong>UserState:</strong> {userState}
					<Button onClick={handleResetUserState} variant="contained" color="primary" size="small" sx={{ ml: 2 }}>
						Reset
					</Button>
				</Typography>
        <Typography variant="h4">
					<strong>Cursor:</strong> {cursor}
					<Button onClick={handleResetCursor} variant="contained" color="primary" size="small" sx={{ ml: 2 }}>
						Reset
					</Button>
				</Typography>
				<Typography variant="h4">
					<strong>Cadastro Azumi:</strong> {dont_has_azumi ? "Não Aceito" : "Aceito"}
				</Typography>
			</Box>
		</Box>
	);
}

