import { Box, Button, TextField, Typography } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { ConversationContext } from "./context/ConversationContext";
import DeliveryManList from "./SideBar/DeliveryManList";
import DeliveryManInfo from "./SideBar/DeliveryManInfo";
import ConversationDetails from "./ConversationDetails";
import { getAllConversations } from "./service/getAllConversations";
import { getConversation } from "./service/getConversation";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { toSeconds } from "../utilFunctions/toSeconds";
import { apiPost } from "../utilFunctions/apiRequests";
import { SelectForm } from "../../global/inputComponents/SelectForm.jsx";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import InputMask from "react-input-mask";

const formatPhoneNumber = (phoneNumber) => {
	if (!phoneNumber) return phoneNumber;

	const cleaned = ("" + phoneNumber).replace(/\D/g, "");

	const match = cleaned.match(/^(\d{2})(\d{2})(\d{4,5})(\d{4})$/);

	if (match) {
		return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
	}

	return phoneNumber;
};

export default function WhatsappChat({ fundName, theme }) {
	const [refreshList, setRefreshList] = useState(true);

	const [hasConversation, setHasConversation] = useState(false);
	const { setConversations, setConversation } = useContext(ConversationContext);
	const [selectedForm, setSelectedForm] = useState(null);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [start, setStart] = useState(dayjs(new Date()));
	const [end, setEnd] = useState(dayjs(new Date()));

	const handleSelectSingle = () => setSelectedForm("single");
	const handleSelectAll = () => setSelectedForm("all");

	const accessToken = useSelector((state) => state.auth0.accessToken);
	const { logout } = useAuth0();

	const [deliveryManData, setDeliveryManData] = useState(null);
	const [error, setError] = useState(null);

	const opsOptions = {
		xama_prod: [
			{ key: "agility", value: "Agility" },
			{ key: "muve", value: "Muve" },
			{ key: "logus", value: "Logus" },
		],
		xama_dev: [
			{ key: "agility", value: "Agility" },
			{ key: "muve", value: "Muve" },
			{ key: "logus", value: "Logus" },
		],
		rondolog_dev: [],
		rondolog_prod: [],
		xpto_dev: [],
		xpto_prod: [],
	};

	const [opsName, setOpsName] = useState("");

	console.info(opsName);

	useEffect(() => {
		if (accessToken && opsName) {
			apiPost(
				"/cadastroInfoWA",
				{
					type: "all",
					dbName: fundName,
					ops: opsName,
				},
				accessToken,
				logout
			)
				.then((value) => {
					setDeliveryManData(value);
					setError(null);
				})
				.catch((err) => {
					console.error(err);
					setError("Erro ao carregar as informações.");
				});
		}
	}, [accessToken, logout, opsName]);

	const handleConversationsSubmit = async (e) => {
		e.preventDefault();
		try {
			const dataConversations = await getAllConversations(
				fundName,
				toSeconds(start),
				toSeconds(end),
				accessToken,
				logout,
				opsName
			);
			setConversations(dataConversations);
			setHasConversation(true);
		} catch (error) {
			console.error(error);
			alert(`WhatsappChat - handleConversationsSubmit: ${error.message}`);
		}
	};

	const handleConversationSubmit = async (e) => {
		e.preventDefault();
		try {
			const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

			let conversationData = await getConversation(
				fundName,
				cleanedPhoneNumber,
				toSeconds(start),
				toSeconds(end),
				accessToken,
				logout,
				opsName
			);

			await apiPost(
				"/cadastroInfoWA",
				{
					type: "individual",
					dbName: fundName,
					ops: opsName,
					phoneNumber: phoneNumber,
				},
				accessToken,
				logout
			)
				.then((deliveryManData) => {
					conversationData = {
						...conversationData,
						...deliveryManData.cadastro[0],
					};

					setError(null);
				})
				.catch((err) => {
					console.error(err);
					setError("Erro ao carregar as informações.");
				});

			console.log(conversationData);
			setConversation(conversationData);
			setHasConversation(true);
		} catch (error) {
			console.error(error);
			alert(`WhatsappChat - handleConversationSubmit: ${error.message}`);
		}
	};

	const handleLast30Minutes = () => {
		const now = new Date();
		const thirtyMinutesAgo = new Date(now.getTime() - 30 * 60 * 1000);

		const nowAdjusted = new Date(now.getTime() - 3 * 60 * 60 * 1000);
		const thirtyMinutesAgoAdjusted = new Date(thirtyMinutesAgo.getTime() - 3 * 60 * 60 * 1000);

		setEnd(dayjs(nowAdjusted.toISOString().slice(0, 16)));
		setStart(dayjs(thirtyMinutesAgoAdjusted.toISOString().slice(0, 16)));
	};

	useEffect(() => {
		setInterval(() => {
			setRefreshList((v) => !v);
		}, 20000);
	}, []);

	const handlePhoneNumberChange = (e) => {
		const rawPhoneNumber = e.target.value;
		const formattedPhoneNumber = formatPhoneNumber(rawPhoneNumber);
		setPhoneNumber(formattedPhoneNumber);
	};

	return (
		<>
			<Box display="flex" alignItems="center" justifyContent="center" height="100%" sx={{ padding: 4 }}>
				<Box
					component="form"
					onSubmit={selectedForm === "all" ? handleConversationsSubmit : handleConversationSubmit}
					width="100%"
					maxWidth="500px"
					p={4}
					bgcolor={theme.palette.primary.light}
					boxShadow={5}
					borderRadius={2}
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Typography variant="h4" fontWeight="bold" color="text.primary" mb={2}>
						Escolha o Chat
					</Typography>

					<SelectForm label="Ops" varValue={opsName || ""} setFun={setOpsName} optionArray={opsOptions[fundName]} />

					<Button
						variant="contained"
						color="primary"
						onClick={handleSelectAll}
						fullWidth
						sx={{
							paddingY: 1.5,
							fontSize: "1.125rem",
							fontWeight: "medium",
						}}
					>
						Consultar Todos
					</Button>

					<Button
						variant="contained"
						onClick={handleSelectSingle}
						fullWidth
						sx={{
							paddingY: 1.5,
							fontSize: "1.125rem",
							fontWeight: "medium",
							mt: 2,
						}}
					>
						Consulta Individual
					</Button>
					<Typography variant="h5" color="text.primary" mb={2} mt={2}>
						Informações da Consulta
					</Typography>

					{selectedForm === "single" && (
						<InputMask mask="+99 (99) 99999-9999" value={phoneNumber} onChange={handlePhoneNumberChange}>
							{() => (
								<TextField
									label="Número de Telefone"
									placeholder="+55 (11) 98765-4321"
									fullWidth
									variant="outlined"
									size="small"
									margin="normal"
									required
								/>
							)}
						</InputMask>
					)}

					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DateTimePicker
							label="Início"
							sx={{ width: "100%" }}
							value={start}
							onChange={(newValue) => setStart(newValue)}
							ampm={false} // 24-hour format
							renderInput={(params) => <TextField {...params} required />}
						/>
						<DateTimePicker
							label="Fim"
							sx={{ width: "100%" }}
							value={end}
							onChange={(newValue) => setEnd(newValue)}
							ampm={false} // 24-hour format
							renderInput={(params) => <TextField {...params} required />}
						/>
					</LocalizationProvider>

					<Button
						onClick={handleLast30Minutes}
						fullWidth
						variant="contained"
						sx={{ mt: 2, backgroundColor: theme.palette.secondary.main }}
					>
						Últimos 30 minutos
					</Button>

					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{ mt: 2, backgroundColor: theme.palette.primary.main }}
					>
						Enviar
					</Button>
				</Box>
			</Box>
			{/* deliveryman list and conversation details */}
			<Box
				display="flex"
				flexDirection="row"
				alignItems="flex-start"
				bgcolor={theme.palette.primary.main}
				justifyContent="center"
				height="100vh"
				width="100%"
			>
				<Box
					height="100%"
					sx={{
						width: "30%",
						maxHeight: "100%",
						overflow: "auto",
					}}
				>
					{selectedForm === "all" && hasConversation && <DeliveryManList />}
					{selectedForm === "single" && hasConversation && <DeliveryManInfo ops={opsName} clientName={fundName} />}
				</Box>

				<Box display="flex" justifyContent="center" width="100%" height="100%">
					{hasConversation && <ConversationDetails setSelectedForm={setSelectedForm} />}
				</Box>
			</Box>
		</>
	);
}
